// /src/Translate.js

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { languageOptions } from './constants/languageOptions';
import { extractLanguageName } from './utils/languageUtils';
import LanguageSelector from './components/LanguageSelector'; // Ensure this import is present
import useProcessedZip from './components/processedZips';
import { submitVideoUrl } from './components/translateService';
import ProgressBar from './components/ProgressBar';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle, FaMicrophone, FaSyncAlt } from 'react-icons/fa';
import Tooltip from './components/Tooltip';
// No separate CSS import as per your instruction

function Translate({ user }) {
    const { t } = useTranslation();

    // State variables
    const getLanguageFromCache = (key, defaultLang) => {
        const storedLang = localStorage.getItem(key);
        return storedLang || defaultLang;
    };

    const [sourceLanguage, setSourceLanguage] = useState(() => getLanguageFromCache('sourceLanguage', 'es'));
    const [targetLanguage, setTargetLanguage] = useState(() => getLanguageFromCache('targetLanguage', 'en'));
    const [videoUrl, setVideoUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [startProgress, setStartProgress] = useState(false);
    const [progressComplete, setProgressComplete] = useState(false);
    const [processingDuration, setProcessingDuration] = useState(0);
    const [selectedAudio, setSelectedAudio] = useState("");
    const [isMultivoice, setIsMultivoice] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isEthicalTranslation, setIsEthicalTranslation] = useState(false); // New state
    const [showEthicalTooltip, setShowEthicalTooltip] = useState(false); // New state
    const audioPlayerRef = useRef(null);

    useEffect(() => {
        localStorage.setItem('sourceLanguage', sourceLanguage);
        localStorage.setItem('targetLanguage', targetLanguage);
    }, [sourceLanguage, targetLanguage]);

    const audioFiles = [
        { name: 'Californian', file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Falloy.mp3?alt=media&token=cb3cbbcd-ea11-45ad-8976-8aa946325855' },
        { name: 'Classic Chris', file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Fecho.mp3?alt=media&token=13f50780-320a-4c33-ab34-c0bfb34ce09f' },
        { name: 'Drama Coach', file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Ffable.mp3?alt=media&token=0744df84-7b2c-4ad9-8308-7344181d62a4' },
        { name: "Harmony Holly", file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Fnova.mp3?alt=media&token=e0c10c93-d2fe-438e-9e1e-57745a14ef56' },
        { name: 'The Guardian', file: '/audio/onyx.mp3' },
        { name: 'Deep Diva', file: '/audio/shimmer.mp3' }
    ];

    const handleAudioChange = (event) => {
        const selectedFile = event.target.value;
        setSelectedAudio(selectedFile);
        if (selectedFile !== 'multivoice' && audioPlayerRef.current) {
            audioPlayerRef.current.src = selectedFile;
            audioPlayerRef.current.load();
            audioPlayerRef.current.play()
                .then(() => console.log('Audio playing'))
                .catch(error => {
                    console.error('Error playing audio:', error);
                    alert(t('playbackFailed', { error: error.message }));
                });
        } else {
            // If multivoice is selected, stop any playing audio
            if (audioPlayerRef.current) {
                audioPlayerRef.current.pause();
                audioPlayerRef.current.src = '';
            }
        }
        if (selectedFile) {
            setIsMultivoice(false); // Uncheck multivoice if a single voice is selected
        }
    };

    const handleReplayNoSound = () => {
        if (isMultivoice) {
            alert(t('multivoiceReplayNotAvailable'));
            return;
        }

        if (!selectedAudio) {
            alert(t('selectVoice'));
        } else {
            audioPlayerRef.current.play()
                .catch(error => {
                    console.error('Error replaying audio:', error);
                    alert(t('playbackFailed', { error: error.message }));
                });
        }
    };

    const handleMultivoiceToggle = () => {
        setIsMultivoice(prev => !prev);
        if (!isMultivoice) {
            setSelectedAudio(""); // Clear selected audio if multivoice is toggled on
            if (audioPlayerRef.current) {
                audioPlayerRef.current.pause();
                audioPlayerRef.current.src = '';
            }
        }
    };

    const handleEthicalTranslationToggle = () => {
        setIsEthicalTranslation(prev => !prev);
    };

    const handleNewVideo = useCallback((video) => {
        if (video) {
            setProgressComplete(true);
        }
    }, []);

    const finishProgressBar = useCallback(() => {
        setProgressComplete(true);
        setTimeout(() => {
            setStartProgress(false);
            setProgressComplete(false);
        }, 500);
    }, []);

    const handleDownloadZip = (url, title) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${title}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const zips = useProcessedZip(user, handleNewVideo, finishProgressBar);

    const renderZips = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                {zips.map(zip => (
                    <div key={zip.id} style={{ margin: '20px', background: '#1a1a2e', padding: '15px', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)' }}>
                        <p style={{ color: 'var(--neon-blue)', fontWeight: 'bold' }}>
                            {zip.title}
                        </p>
                        <button onClick={() => handleDownloadZip(zip.url, zip.title)} className="download-button"
                            style={{
                                padding: '8px 12px',
                                border: 'none',
                                borderRadius: '4px',
                                backgroundColor: '#00c6ff',
                                color: '#fff',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s'
                            }}
                            onMouseOver={(e) => e.target.style.backgroundColor = '#009bbd'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#00c6ff'}
                        >
                            {t('download')}
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    const handleTranslate = async () => {
        if (!user) {
            alert(t('pleaseSignIn'));
            return;
        }
        if (!videoUrl) {
            alert(t('enterYoutubeLink'));
            return;
        }

        if (!isMultivoice && !selectedAudio) {
            alert(t('selectVoiceChoice'));
            return;
        }

        setLoading(true);

        const sourceLanguageName = extractLanguageName(languageOptions.find(option => option.value === sourceLanguage)?.label || '');
        const targetLanguageName = extractLanguageName(languageOptions.find(option => option.value === targetLanguage)?.label || '');

        let voiceName = '';

        if (isMultivoice) {
            voiceName = 'multivoice'; // Set voiceName to 'multivoice'
        } else {
            voiceName = audioFiles.find(audio => audio.file === selectedAudio)?.name || '';
        }

        try {
            if (videoUrl) {
                const response = await submitVideoUrl({
                    user,
                    videoUrl,
                    sourceLanguageName,
                    targetLanguageName,
                    voice: voiceName, // Send 'multivoice' or selected voice
                    ethicalTranslation: isEthicalTranslation // New field
                });
                setProcessingDuration(response.video_duration / 1);
                setStartProgress(true);
                setProgressComplete(false);
            }
        } catch (error) {
            console.error("Error:", error);

            if (error.error === 'Insufficient credits') {
                alert(t('insufficientCredits'));
            } else {
                alert(error.error);
            }
            setLoading(false);
            return;
        }
        setLoading(false);
    };

    return (
        <div className="translate-page">
            <div className="translate-container">
                <div className="upload-container">
                    {/* Existing upload inputs */}
                    <div className="upload-inputs">
                        <div className="url-upload">
                            <input
                                type="text"
                                placeholder={t('pasteYoutubeLink')}
                                value={videoUrl}
                                onChange={(e) => setVideoUrl(e.target.value)}
                                style={{
                                    backgroundColor: '#1a1a2e',
                                    color: 'var(--text-color)',
                                    padding: '10px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    width: '100%',
                                    boxSizing: 'border-box'
                                }}
                            />
                        </div>
                    </div>

                    {/* Language Selector Component */}
                    <div className="language-selector-container" style={{ marginTop: '20px' }}>
                        <LanguageSelector
                            targetLanguage={targetLanguage}
                            setTargetLanguage={setTargetLanguage}
                            sourceLanguage={sourceLanguage}
                            setSourceLanguage={setSourceLanguage}
                        />
                    </div>

                    {/* Voice and Ethical Translation Selection */}
                    <div className="voice-ethical-selection" style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: '20px',
                        marginTop: '20px'
                    }}>
                        {/* Multivoice Toggle */}
                        <div className="multivoice-toggle" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <label className="multivoice-label" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input
                                    type="checkbox"
                                    checked={isMultivoice}
                                    onChange={handleMultivoiceToggle}
                                />
                                {t('multivoice')}
                            </label>
                            <FaInfoCircle
                                className="info-icon"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowTooltip(!showTooltip)}
                            />
                            {showTooltip && (
                                <Tooltip onClose={() => setShowTooltip(false)}>
                                    {t('multivoiceInfo')}
                                </Tooltip>
                            )}
                        </div>

                        {/* Ethical Translation Toggle */}
                        <div className="ethical-translation-toggle" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <label className="ethical-label" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input
                                    type="checkbox"
                                    checked={isEthicalTranslation}
                                    onChange={handleEthicalTranslationToggle}
                                />
                                {t('ethicalTranslation')}
                            </label>
                            <FaInfoCircle
                                className="info-icon"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowEthicalTooltip(!showEthicalTooltip)}
                            />
                            {showEthicalTooltip && (
                                <Tooltip onClose={() => setShowEthicalTooltip(false)}>
                                    {t('ethicalTranslationInfo')}
                                </Tooltip>
                            )}
                        </div>
                    </div>

                    {/* Voice Selection and Replay */}
                    <div className="voice-selection-dropdown-replay">
                        {!isMultivoice && (
                            <>
                                <select
                                    value={selectedAudio}
                                    onChange={handleAudioChange}
                                    className="voice-select"
                                >
                                    <option value="">{t('selectVoice')}</option>
                                    {audioFiles.map(audio => (
                                        <option key={audio.file} value={audio.file}>{audio.name}</option>
                                    ))}
                                </select>
                                <button
                                    onClick={handleReplayNoSound}
                                    disabled={!selectedAudio}
                                    className="replay-button"
                                >
                                    <FaSyncAlt />
                                    {t('replay')}
                                </button>
                            </>
                        )}
                    </div>

                    {/* Progress Bar and Translate Button */}
                    {startProgress ? (
                        <div className="progress-bar-wrapper" style={{ marginTop: '20px' }}>
                            <span className="do-not-refresh-text" style={{ display: 'block', marginBottom: '10px' }}>
                                {t('doNotRefresh', { duration: Math.round(processingDuration) })}
                            </span>
                            <ProgressBar
                                duration={processingDuration}
                                startProgress={startProgress}
                                completeImmediately={progressComplete}
                            />
                        </div>
                    ) : (
                        <button
                            className="translate-button"
                            onClick={handleTranslate}
                            disabled={loading}
                            style={{
                                marginTop: '20px',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '4px',
                                backgroundColor: '#00c6ff',
                                color: '#fff',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                fontSize: '16px',
                                transition: 'background-color 0.3s'
                            }}
                            onMouseOver={(e) => {
                                if (!loading) e.target.style.backgroundColor = '#009bbd';
                            }}
                            onMouseOut={(e) => {
                                if (!loading) e.target.style.backgroundColor = '#00c6ff';
                            }}
                        >
                            {loading ? t('translating') : (
                                <>
                                    <FaMicrophone />
                                    {t('startTranslation')}
                                </>
                            )}
                        </button>
                    )}
                </div>
            </div>
            {renderZips()}
            <div></div>
            <audio ref={audioPlayerRef} controls hidden style={{ display: 'none' }}></audio>
        </div>
    );

}

export default Translate;